var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/**
 * Tagify (v 4.12.0) - tags input component
 * By Yair Even-Or
 * https://github.com/yairEO/tagify
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 * 
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 * 
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 * 
 * THE SOFTWARE IS NOT PERMISSIBLE TO BE SOLD.
 */
!function (t, e) {
  exports = e();
}(exports, function () {
  "use strict";

  function t(t, e) {
    var i = Object.keys(t);

    if (Object.getOwnPropertySymbols) {
      var s = Object.getOwnPropertySymbols(t);
      e && (s = s.filter(function (e) {
        return Object.getOwnPropertyDescriptor(t, e).enumerable;
      })), i.push.apply(i, s);
    }

    return i;
  }

  function e(e) {
    for (var s = 1; s < arguments.length; s++) {
      var a = null != arguments[s] ? arguments[s] : {};
      s % 2 ? t(Object(a), !0).forEach(function (t) {
        i(e, t, a[t]);
      }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(a)) : t(Object(a)).forEach(function (t) {
        Object.defineProperty(e, t, Object.getOwnPropertyDescriptor(a, t));
      });
    }

    return e;
  }

  function i(t, e, i) {
    return e in t ? Object.defineProperty(t, e, {
      value: i,
      enumerable: !0,
      configurable: !0,
      writable: !0
    }) : t[e] = i, t;
  }

  const s = (t, e, i, s) => (t = "" + t, e = "" + e, s && (t = t.trim(), e = e.trim()), i ? t == e : t.toLowerCase() == e.toLowerCase()),
        a = (t, e) => t && Array.isArray(t) && t.map(t => n(t, e));

  function n(t, e) {
    var i,
        s = {};

    for (i in t) e.indexOf(i) < 0 && (s[i] = t[i]);

    return s;
  }

  function o(t) {
    var e = document.createElement("div");
    return t.replace(/\&#?[0-9a-z]+;/gi, function (t) {
      return e.innerHTML = t, e.innerText;
    });
  }

  function r(t) {
    return new DOMParser().parseFromString(t.trim(), "text/html").body.firstElementChild;
  }

  function l(t, e) {
    for (e = e || "previous"; t = t[e + "Sibling"];) if (3 == t.nodeType) return t;
  }

  function d(t) {
    return "string" == typeof t ? t.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/`|'/g, "&#039;") : t;
  }

  function h(t) {
    var e = Object.prototype.toString.call(t).split(" ")[1].slice(0, -1);
    return t === Object(t) && "Array" != e && "Function" != e && "RegExp" != e && "HTMLUnknownElement" != e;
  }

  function g(t, e, i) {
    function s(t, e) {
      for (var i in e) if (e.hasOwnProperty(i)) {
        if (h(e[i])) {
          h(t[i]) ? s(t[i], e[i]) : t[i] = Object.assign({}, e[i]);
          continue;
        }

        if (Array.isArray(e[i])) {
          t[i] = Object.assign([], e[i]);
          continue;
        }

        t[i] = e[i];
      }
    }

    return t instanceof Object || (t = {}), s(t, e), i && s(t, i), t;
  }

  function p() {
    const t = [],
          e = {};

    for (let i of arguments) for (let s of i) h(s) ? e[s.value] || (t.push(s), e[s.value] = 1) : t.includes(s) || t.push(s);

    return t;
  }

  function c(t) {
    return String.prototype.normalize ? "string" == typeof t ? t.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : void 0 : t;
  }

  var u = () => /(?=.*chrome)(?=.*android)/i.test(navigator.userAgent);

  function m() {
    return ([10000000] + -1000 + -4000 + -8000 + -100000000000).replace(/[018]/g, t => (t ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> t / 4).toString(16));
  }

  function v(t) {
    return t && t.classList && t.classList.contains((this || _global).settings.classNames.tag);
  }

  var f = {
    delimiters: ",",
    pattern: null,
    tagTextProp: "value",
    maxTags: 1 / 0,
    callbacks: {},
    addTagOnBlur: !0,
    duplicates: !1,
    whitelist: [],
    blacklist: [],
    enforceWhitelist: !1,
    userInput: !0,
    keepInvalidTags: !1,
    mixTagsAllowedAfter: /,|\.|\:|\s/,
    mixTagsInterpolator: ["[[", "]]"],
    backspace: !0,
    skipInvalid: !1,
    pasteAsTags: !0,
    editTags: {
      clicks: 2,
      keepInvalid: !0
    },
    transformTag: () => {},
    trim: !0,
    a11y: {
      focusableTags: !1
    },
    mixMode: {
      insertAfterTag: "\xA0"
    },
    autoComplete: {
      enabled: !0,
      rightKey: !1
    },
    classNames: {
      namespace: "tagify",
      mixMode: "tagify--mix",
      selectMode: "tagify--select",
      input: "tagify__input",
      focus: "tagify--focus",
      tagNoAnimation: "tagify--noAnim",
      tagInvalid: "tagify--invalid",
      tagNotAllowed: "tagify--notAllowed",
      scopeLoading: "tagify--loading",
      hasMaxTags: "tagify--hasMaxTags",
      hasNoTags: "tagify--noTags",
      empty: "tagify--empty",
      inputInvalid: "tagify__input--invalid",
      dropdown: "tagify__dropdown",
      dropdownWrapper: "tagify__dropdown__wrapper",
      dropdownHeader: "tagify__dropdown__header",
      dropdownFooter: "tagify__dropdown__footer",
      dropdownItem: "tagify__dropdown__item",
      dropdownItemActive: "tagify__dropdown__item--active",
      dropdownInital: "tagify__dropdown--initial",
      tag: "tagify__tag",
      tagText: "tagify__tag-text",
      tagX: "tagify__tag__removeBtn",
      tagLoading: "tagify__tag--loading",
      tagEditing: "tagify__tag--editable",
      tagFlash: "tagify__tag--flash",
      tagHide: "tagify__tag--hide"
    },
    dropdown: {
      classname: "",
      enabled: 2,
      maxItems: 10,
      searchKeys: ["value", "searchBy"],
      fuzzySearch: !0,
      caseSensitive: !1,
      accentedSearch: !0,
      highlightFirst: !1,
      closeOnSelect: !0,
      clearOnSelect: !0,
      position: "all",
      appendTarget: null
    },
    hooks: {
      beforeRemoveTag: () => Promise.resolve(),
      beforePaste: () => Promise.resolve(),
      suggestionClick: () => Promise.resolve()
    }
  };

  function T() {
    (this || _global).dropdown = {};

    for (let t in (this || _global)._dropdown) (this || _global).dropdown[t] = "function" == typeof (this || _global)._dropdown[t] ? (this || _global)._dropdown[t].bind(this || _global) : (this || _global)._dropdown[t];

    (this || _global).dropdown.refs();
  }

  var w = {
    refs() {
      (this || _global).DOM.dropdown = this.parseTemplate("dropdown", [(this || _global).settings]), (this || _global).DOM.dropdown.content = (this || _global).DOM.dropdown.querySelector("[data-selector='tagify-dropdown-wrapper']");
    },

    show(t) {
      var e,
          i,
          a,
          n = (this || _global).settings,
          o = "mix" == n.mode && !n.enforceWhitelist,
          r = !n.whitelist || !n.whitelist.length,
          l = "manual" == n.dropdown.position;

      if (t = void 0 === t ? (this || _global).state.inputText : t, (!r || o || n.templates.dropdownItemNoMatch) && !1 !== n.dropdown.enable && !(this || _global).state.isLoading) {
        if (clearTimeout((this || _global).dropdownHide__bindEventsTimeout), (this || _global).suggestedListItems = (this || _global).dropdown.filterListItems(t), t && !(this || _global).suggestedListItems.length && (this.trigger("dropdown:noMatch", t), n.templates.dropdownItemNoMatch && (a = n.templates.dropdownItemNoMatch.call(this || _global, {
          value: t
        }))), !a) {
          if ((this || _global).suggestedListItems.length) t && o && !(this || _global).state.editing.scope && !s((this || _global).suggestedListItems[0].value, t) && (this || _global).suggestedListItems.unshift({
            value: t
          });else {
            if (!t || !o || (this || _global).state.editing.scope) return (this || _global).input.autocomplete.suggest.call(this || _global), void (this || _global).dropdown.hide();
            (this || _global).suggestedListItems = [{
              value: t
            }];
          }
          i = "" + (h(e = (this || _global).suggestedListItems[0]) ? e.value : e), n.autoComplete && i && 0 == i.indexOf(t) && (this || _global).input.autocomplete.suggest.call(this || _global, e);
        }

        (this || _global).dropdown.fill(a), n.dropdown.highlightFirst && (this || _global).dropdown.highlightOption((this || _global).DOM.dropdown.content.children[0]), (this || _global).state.dropdown.visible || setTimeout((this || _global).dropdown.events.binding.bind(this || _global)), (this || _global).state.dropdown.visible = t || !0, (this || _global).state.dropdown.query = t, this.setStateSelection(), l || setTimeout(() => {
          (this || _global).dropdown.position(), (this || _global).dropdown.render();
        }), setTimeout(() => {
          this.trigger("dropdown:show", (this || _global).DOM.dropdown);
        });
      }
    },

    hide(t) {
      var e = (this || _global).DOM,
          i = e.scope,
          s = e.dropdown,
          a = "manual" == (this || _global).settings.dropdown.position && !t;
      if (s && document.body.contains(s) && !a) return window.removeEventListener("resize", (this || _global).dropdown.position), (this || _global).dropdown.events.binding.call(this || _global, !1), i.setAttribute("aria-expanded", !1), s.parentNode.removeChild(s), setTimeout(() => {
        (this || _global).state.dropdown.visible = !1;
      }, 100), (this || _global).state.dropdown.query = (this || _global).state.ddItemData = (this || _global).state.ddItemElm = (this || _global).state.selection = null, (this || _global).state.tag && (this || _global).state.tag.value.length && ((this || _global).state.flaggedTags[(this || _global).state.tag.baseOffset] = (this || _global).state.tag), this.trigger("dropdown:hide", s), this || _global;
    },

    toggle(t) {
      (this || _global).dropdown[(this || _global).state.dropdown.visible && !t ? "hide" : "show"]();
    },

    render() {
      var t,
          e,
          i,
          s = (t = (this || _global).DOM.dropdown, (i = t.cloneNode(!0)).style.cssText = "position:fixed; top:-9999px; opacity:0", document.body.appendChild(i), e = i.clientHeight, i.parentNode.removeChild(i), e),
          a = (this || _global).settings;
      return "number" == typeof a.dropdown.enabled && a.dropdown.enabled >= 0 ? ((this || _global).DOM.scope.setAttribute("aria-expanded", !0), document.body.contains((this || _global).DOM.dropdown) || ((this || _global).DOM.dropdown.classList.add(a.classNames.dropdownInital), (this || _global).dropdown.position(s), a.dropdown.appendTarget.appendChild((this || _global).DOM.dropdown), setTimeout(() => (this || _global).DOM.dropdown.classList.remove(a.classNames.dropdownInital))), this || _global) : this || _global;
    },

    fill(t) {
      t = "string" == typeof t ? t : (this || _global).dropdown.createListHTML(t || (this || _global).suggestedListItems);

      var e,
          i = (this || _global).settings.templates.dropdownContent.call(this || _global, t);

      (this || _global).DOM.dropdown.content.innerHTML = (e = i) ? e.replace(/\>[\r\n ]+\</g, "><").replace(/(<.*?>)|\s+/g, (t, e) => e || " ") : "";
    },

    refilter(t) {
      t = t || (this || _global).state.dropdown.query || "", (this || _global).suggestedListItems = (this || _global).dropdown.filterListItems(t), (this || _global).dropdown.fill(), (this || _global).suggestedListItems.length || (this || _global).dropdown.hide(), this.trigger("dropdown:updated", (this || _global).DOM.dropdown);
    },

    position(t) {
      var e = (this || _global).settings.dropdown;

      if ("manual" != e.position) {
        var i,
            s,
            a,
            n,
            o,
            r,
            l = (this || _global).DOM.dropdown,
            d = e.placeAbove,
            h = document.documentElement.clientHeight,
            g = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) > 480 ? e.position : "all",
            p = (this || _global).DOM["input" == g ? "input" : "scope"];
        t = t || l.clientHeight, (this || _global).state.dropdown.visible && ("text" == g ? (a = (i = this.getCaretGlobalPosition()).bottom, s = i.top, n = i.left, o = "auto") : (r = function (t) {
          for (var e = 0, i = 0; t;) e += t.offsetLeft || 0, i += t.offsetTop || 0, t = t.parentNode;

          return {
            left: e,
            top: i
          };
        }((this || _global).settings.dropdown.appendTarget), s = (i = p.getBoundingClientRect()).top - r.top, a = i.bottom - 1 - r.top, n = i.left - r.left, o = i.width + "px"), s = Math.floor(s), a = Math.ceil(a), d = void 0 === d ? h - i.bottom < t : d, l.style.cssText = "left:" + (n + window.pageXOffset) + "px; width:" + o + ";" + (d ? "top: " + (s + window.pageYOffset) + "px" : "top: " + (a + window.pageYOffset) + "px"), l.setAttribute("placement", d ? "top" : "bottom"), l.setAttribute("position", g));
      }
    },

    events: {
      binding(t = !0) {
        var e = (this || _global).dropdown.events.callbacks,
            i = (this || _global).listeners.dropdown = (this || _global).listeners.dropdown || {
          position: (this || _global).dropdown.position.bind(this || _global),
          onKeyDown: e.onKeyDown.bind(this || _global),
          onMouseOver: e.onMouseOver.bind(this || _global),
          onMouseLeave: e.onMouseLeave.bind(this || _global),
          onClick: e.onClick.bind(this || _global),
          onScroll: e.onScroll.bind(this || _global)
        },
            s = t ? "addEventListener" : "removeEventListener";
        "manual" != (this || _global).settings.dropdown.position && (window[s]("resize", i.position), window[s]("keydown", i.onKeyDown)), (this || _global).DOM.dropdown[s]("mouseover", i.onMouseOver), (this || _global).DOM.dropdown[s]("mouseleave", i.onMouseLeave), (this || _global).DOM.dropdown[s]("mousedown", i.onClick), (this || _global).DOM.dropdown.content[s]("scroll", i.onScroll);
      },

      callbacks: {
        onKeyDown(t) {
          var e = (this || _global).DOM.dropdown.querySelector((this || _global).settings.classNames.dropdownItemActiveSelector),
              i = (this || _global).dropdown.getSuggestionDataByNode(e);

          switch (t.key) {
            case "ArrowDown":
            case "ArrowUp":
            case "Down":
            case "Up":
              var s;
              t.preventDefault(), e && (e = e[("ArrowUp" == t.key || "Up" == t.key ? "previous" : "next") + "ElementSibling"]), e || (s = (this || _global).DOM.dropdown.content.children, e = s["ArrowUp" == t.key || "Up" == t.key ? s.length - 1 : 0]), i = (this || _global).dropdown.getSuggestionDataByNode(e), (this || _global).dropdown.highlightOption(e, !0);
              break;

            case "Escape":
            case "Esc":
              (this || _global).dropdown.hide();

              break;

            case "ArrowRight":
              if ((this || _global).state.actions.ArrowLeft) return;

            case "Tab":
              if ("mix" != (this || _global).settings.mode && e && !(this || _global).settings.autoComplete.rightKey && !(this || _global).state.editing) {
                t.preventDefault();

                var a = (this || _global).dropdown.getMappedValue(i);

                return (this || _global).input.autocomplete.set.call(this || _global, a), !1;
              }

              return !0;

            case "Enter":
              t.preventDefault(), (this || _global).settings.hooks.suggestionClick(t, {
                tagify: this || _global,
                tagData: i,
                suggestionElm: e
              }).then(() => {
                if (e) return (this || _global).dropdown.selectOption(e);
                (this || _global).dropdown.hide(), "mix" != (this || _global).settings.mode && this.addTags((this || _global).state.inputText.trim(), !0);
              }).catch(t => t);
              break;

            case "Backspace":
              {
                if ("mix" == (this || _global).settings.mode || (this || _global).state.editing.scope) return;

                const t = (this || _global).input.raw.call(this || _global);

                "" != t && 8203 != t.charCodeAt(0) || (!0 === (this || _global).settings.backspace ? this.removeTags() : "edit" == (this || _global).settings.backspace && setTimeout((this || _global).editTag.bind(this || _global), 0));
              }
          }
        },

        onMouseOver(t) {
          var e = t.target.closest((this || _global).settings.classNames.dropdownItemSelector);
          e && (this || _global).dropdown.highlightOption(e);
        },

        onMouseLeave(t) {
          (this || _global).dropdown.highlightOption();
        },

        onClick(t) {
          if (0 == t.button && t.target != (this || _global).DOM.dropdown && t.target != (this || _global).DOM.dropdown.content) {
            var e = t.target.closest((this || _global).settings.classNames.dropdownItemSelector),
                i = (this || _global).dropdown.getSuggestionDataByNode(e);

            (this || _global).state.actions.selectOption = !0, setTimeout(() => (this || _global).state.actions.selectOption = !1, 50), (this || _global).settings.hooks.suggestionClick(t, {
              tagify: this || _global,
              tagData: i,
              suggestionElm: e
            }).then(() => {
              e ? (this || _global).dropdown.selectOption(e) : (this || _global).dropdown.hide();
            }).catch(t => console.warn(t));
          }
        },

        onScroll(t) {
          var e = t.target,
              i = e.scrollTop / (e.scrollHeight - e.parentNode.clientHeight) * 100;
          this.trigger("dropdown:scroll", {
            percentage: Math.round(i)
          });
        }

      }
    },

    getSuggestionDataByNode(t) {
      var e = t ? +t.getAttribute("tagifySuggestionIdx") : -1;
      return (this || _global).suggestedListItems[e] || null;
    },

    highlightOption(t, e) {
      var i,
          s = (this || _global).settings.classNames.dropdownItemActive;
      if ((this || _global).state.ddItemElm && ((this || _global).state.ddItemElm.classList.remove(s), (this || _global).state.ddItemElm.removeAttribute("aria-selected")), !t) return (this || _global).state.ddItemData = null, (this || _global).state.ddItemElm = null, void (this || _global).input.autocomplete.suggest.call(this || _global);
      i = (this || _global).suggestedListItems[this.getNodeIndex(t)], (this || _global).state.ddItemData = i, (this || _global).state.ddItemElm = t, t.classList.add(s), t.setAttribute("aria-selected", !0), e && (t.parentNode.scrollTop = t.clientHeight + t.offsetTop - t.parentNode.clientHeight), (this || _global).settings.autoComplete && ((this || _global).input.autocomplete.suggest.call(this || _global, i), (this || _global).dropdown.position());
    },

    selectOption(t) {
      var e = (this || _global).settings.dropdown,
          i = e.clearOnSelect,
          s = e.closeOnSelect;
      if (!t) return this.addTags((this || _global).state.inputText, !0), void (s && (this || _global).dropdown.hide());
      var a = t.getAttribute("tagifySuggestionIdx"),
          n = (this || _global).suggestedListItems[+a];
      this.trigger("dropdown:select", {
        data: n,
        elm: t
      }), a && n ? ((this || _global).state.editing ? this.onEditTagDone(null, g({
        __isValid: !0
      }, this.normalizeTags([n])[0])) : this["mix" == (this || _global).settings.mode ? "addMixTags" : "addTags"]([n], i), (this || _global).DOM.input.parentNode && (setTimeout(() => {
        (this || _global).DOM.input.focus(), this.toggleFocusClass(!0);
      }), s ? setTimeout((this || _global).dropdown.hide.bind(this || _global)) : (this || _global).dropdown.refilter())) : (this || _global).dropdown.hide();
    },

    selectAll(t) {
      (this || _global).suggestedListItems.length = 0, (this || _global).dropdown.hide(), (this || _global).dropdown.filterListItems("");

      var e = (this || _global).dropdown.filterListItems("");

      return t || (e = (this || _global).state.dropdown.suggestions), this.addTags(e, !0), this || _global;
    },

    filterListItems(t, e) {
      var i,
          s,
          a,
          n,
          o,
          r = (this || _global).settings,
          l = r.dropdown,
          d = (e = e || {}, t = "select" == r.mode && (this || _global).value.length && (this || _global).value[0][r.tagTextProp] == t ? "" : t, []),
          g = [],
          p = r.whitelist,
          u = l.maxItems >= 0 ? l.maxItems : 1 / 0,
          m = l.searchKeys,
          v = 0;
      if (!t || !m.length) return d = r.duplicates ? p : p.filter(t => !this.isTagDuplicate(h(t) ? t.value : t)), (this || _global).state.dropdown.suggestions = d, d.slice(0, u);

      function f(t, e) {
        return e.toLowerCase().split(" ").every(e => t.includes(e.toLowerCase()));
      }

      for (o = l.caseSensitive ? "" + t : ("" + t).toLowerCase(); v < p.length; v++) {
        let t, u;
        i = p[v] instanceof Object ? p[v] : {
          value: p[v]
        };
        let T = !Object.keys(i).some(t => m.includes(t)) ? ["value"] : m;
        l.fuzzySearch && !e.exact ? (a = T.reduce((t, e) => t + " " + (i[e] || ""), "").toLowerCase().trim(), l.accentedSearch && (a = c(a), o = c(o)), t = 0 == a.indexOf(o), u = a === o, s = f(a, o)) : (t = !0, s = T.some(t => {
          var s = "" + (i[t] || "");
          return l.accentedSearch && (s = c(s), o = c(o)), l.caseSensitive || (s = s.toLowerCase()), u = s === o, e.exact ? s === o : 0 == s.indexOf(o);
        })), n = !r.duplicates && this.isTagDuplicate(h(i) ? i.value : i), s && !n && (u && t ? g.push(i) : "startsWith" == l.sortby && t ? d.unshift(i) : d.push(i));
      }

      return (this || _global).state.dropdown.suggestions = g.concat(d), "function" == typeof l.sortby ? l.sortby(g.concat(d), o) : g.concat(d).slice(0, u);
    },

    getMappedValue(t) {
      var e = (this || _global).settings.dropdown.mapValueTo;
      return e ? "function" == typeof e ? e(t) : t[e] || t.value : t.value;
    },

    createListHTML(t) {
      return g([], t).map((t, e) => {
        "string" != typeof t && "number" != typeof t || (t = {
          value: t
        });

        var i = (this || _global).dropdown.getMappedValue(t);

        t.value = "string" == typeof i ? d(i) : i;

        var s = (this || _global).settings.templates.dropdownItem.apply(this || _global, [t, this || _global]);

        return s = s.replace(/\s*tagifySuggestionIdx=(["'])(.*?)\1/gim, "").replace(">", ` tagifySuggestionIdx="${e}">`);
      }).join("");
    }

  };
  const b = "@yaireo/tagify/";
  var y,
      x = {
    empty: "empty",
    exceed: "number of tags exceeded",
    pattern: "pattern mismatch",
    duplicate: "already exists",
    notAllowed: "not allowed"
  },
      D = {
    wrapper: (t, e) => `<tags class="${e.classNames.namespace} ${e.mode ? `${e.classNames[e.mode + "Mode"]}` : ""} ${t.className}"\n                    ${e.readonly ? "readonly" : ""}\n                    ${e.disabled ? "disabled" : ""}\n                    ${e.required ? "required" : ""}\n                    ${"select" === e.mode ? "spellcheck='false'" : ""}\n                    tabIndex="-1">\n            <span ${!e.readonly && e.userInput ? "contenteditable" : ""} tabIndex="0" data-placeholder="${e.placeholder || "&#8203;"}" aria-placeholder="${e.placeholder || ""}"\n                class="${e.classNames.input}"\n                role="textbox"\n                aria-autocomplete="both"\n                aria-multiline="${"mix" == e.mode}"></span>\n                &#8203;\n        </tags>`,

    tag(t, e) {
      var i = (this || _global).settings;
      return `<tag title="${t.title || t.value}"\n                    contenteditable='false'\n                    spellcheck='false'\n                    tabIndex="${i.a11y.focusableTags ? 0 : -1}"\n                    class="${i.classNames.tag} ${t.class || ""}"\n                    ${this.getAttributes(t)}>\n            <x title='' class="${i.classNames.tagX}" role='button' aria-label='remove tag'></x>\n            <div>\n                <span class="${i.classNames.tagText}">${t[i.tagTextProp] || t.value}</span>\n            </div>\n        </tag>`;
    },

    dropdown(t) {
      var e = t.dropdown,
          i = "manual" == e.position,
          s = `${t.classNames.dropdown}`;
      return `<div class="${i ? "" : s} ${e.classname}" role="listbox" aria-labelledby="dropdown">\n                    <div data-selector='tagify-dropdown-wrapper' class="${t.classNames.dropdownWrapper}"></div>\n                </div>`;
    },

    dropdownContent(t) {
      var e = (this || _global).settings,
          i = (this || _global).state.dropdown.suggestions;
      return `\n            ${e.templates.dropdownHeader.call(this || _global, i)}\n            ${t}\n            ${e.templates.dropdownFooter.call(this || _global, i)}\n        `;
    },

    dropdownItem(t, e) {
      return `<div ${this.getAttributes(t)}\n                    class='${(this || _global).settings.classNames.dropdownItem} ${t.class ? t.class : ""}'\n                    tabindex="0"\n                    role="option">${t.value}</div>`;
    },

    dropdownHeader: t => "",

    dropdownFooter(t) {
      var e = t.length - (this || _global).settings.dropdown.maxItems;
      return e > 0 ? `<footer data-selector='tagify-suggestions-footer' class="${(this || _global).settings.classNames.dropdownFooter}">\n                ${e} more items. Refine your search.\n            </footer>` : "";
    },

    dropdownItemNoMatch: null
  };
  var O = {
    customBinding() {
      (this || _global).customEventsList.forEach(t => {
        this.on(t, (this || _global).settings.callbacks[t]);
      });
    },

    binding(t = !0) {
      var e,
          i = (this || _global).events.callbacks,
          s = t ? "addEventListener" : "removeEventListener";

      if (!(this || _global).state.mainEvents || !t) {
        for (var a in (this || _global).state.mainEvents = t, t && !(this || _global).listeners.main && ((this || _global).events.bindGlobal.call(this || _global), (this || _global).settings.isJQueryPlugin && jQuery((this || _global).DOM.originalInput).on("tagify.removeAllTags", (this || _global).removeAllTags.bind(this || _global))), e = (this || _global).listeners.main = (this || _global).listeners.main || {
          focus: ["input", i.onFocusBlur.bind(this || _global)],
          keydown: ["input", i.onKeydown.bind(this || _global)],
          click: ["scope", i.onClickScope.bind(this || _global)],
          dblclick: ["scope", i.onDoubleClickScope.bind(this || _global)],
          paste: ["input", i.onPaste.bind(this || _global)],
          drop: ["input", i.onDrop.bind(this || _global)]
        }) (this || _global).DOM[e[a][0]][s](a, e[a][1]);

        clearInterval((this || _global).listeners.main.originalInputValueObserverInterval), (this || _global).listeners.main.originalInputValueObserverInterval = setInterval(i.observeOriginalInputValue.bind(this || _global), 500);
        var n = (this || _global).listeners.main.inputMutationObserver || new MutationObserver(i.onInputDOMChange.bind(this || _global));
        n && n.disconnect(), "mix" == (this || _global).settings.mode && n.observe((this || _global).DOM.input, {
          childList: !0
        });
      }
    },

    bindGlobal(t) {
      var e,
          i = (this || _global).events.callbacks,
          s = t ? "removeEventListener" : "addEventListener";
      if (t || !(this || _global).listeners.global) for (e of ((this || _global).listeners.global = (this || _global).listeners && (this || _global).listeners.global || [{
        type: (this || _global).isIE ? "keydown" : "input",
        target: (this || _global).DOM.input,
        cb: i[(this || _global).isIE ? "onInputIE" : "onInput"].bind(this || _global)
      }, {
        type: "keydown",
        target: window,
        cb: i.onWindowKeyDown.bind(this || _global)
      }, {
        type: "blur",
        target: (this || _global).DOM.input,
        cb: i.onFocusBlur.bind(this || _global)
      }], (this || _global).listeners.global)) e.target[s](e.type, e.cb);
    },

    unbindGlobal() {
      (this || _global).events.bindGlobal.call(this || _global, !0);
    },

    callbacks: {
      onFocusBlur(t) {
        var e = t.target ? this.trim(t.target.textContent) : "",
            i = (this || _global).settings,
            s = t.type,
            a = i.dropdown.enabled >= 0,
            n = {
          relatedTarget: t.relatedTarget
        },
            o = (this || _global).state.actions.selectOption && (a || !i.dropdown.closeOnSelect),
            r = (this || _global).state.actions.addNew && a,
            l = t.relatedTarget && v.call(this || _global, t.relatedTarget) && (this || _global).DOM.scope.contains(t.relatedTarget);

        if ("blur" == s) {
          if (t.relatedTarget === (this || _global).DOM.scope) return (this || _global).dropdown.hide(), void (this || _global).DOM.input.focus();
          this.postUpdate(), this.triggerChangeEvent();
        }

        if (!o && !r) if ((this || _global).state.hasFocus = "focus" == s && +new Date(), this.toggleFocusClass((this || _global).state.hasFocus), "mix" != i.mode) {
          if ("focus" == s) return this.trigger("focus", n), void (0 !== i.dropdown.enabled && i.userInput || (this || _global).dropdown.show((this || _global).value.length ? "" : void 0));

          if ("blur" == s) {
            var d, h;
            this.trigger("blur", n), this.loading(!1);
            let t = i.enforceWhitelist ? !!this.getWhitelistItem(null === (d = (this || _global).value) || void 0 === d || null === (h = d[0]) || void 0 === h ? void 0 : h.value) : i.keepInvalidTags;
            "select" == (this || _global).settings.mode && l && (e = ""), "select" !== (this || _global).settings.mode && e && !(this || _global).state.actions.selectOption && i.addTagOnBlur && this.addTags(e, !0), "select" != (this || _global).settings.mode || e && t || this.removeTags();
          }

          (this || _global).DOM.input.removeAttribute("style"), (this || _global).dropdown.hide();
        } else "focus" == s ? this.trigger("focus", n) : "blur" == t.type && (this.trigger("blur", n), this.loading(!1), (this || _global).dropdown.hide(), (this || _global).state.dropdown.visible = void 0, this.setStateSelection());
      },

      onWindowKeyDown(t) {
        var e,
            i = document.activeElement;
        if (v.call(this || _global, i) && (this || _global).DOM.scope.contains(document.activeElement)) switch (e = i.nextElementSibling, t.key) {
          case "Backspace":
            (this || _global).settings.readonly || (this.removeTags(i), (e || (this || _global).DOM.input).focus());
            break;

          case "Enter":
            setTimeout((this || _global).editTag.bind(this || _global), 0, i);
        }
      },

      onKeydown(t) {
        var e = (this || _global).settings;
        "select" == e.mode && e.enforceWhitelist && (this || _global).value.length && "Tab" != t.key && t.preventDefault();
        var i = this.trim(t.target.textContent);

        if (this.trigger("keydown", {
          originalEvent: this.cloneEvent(t)
        }), "mix" == e.mode) {
          switch (t.key) {
            case "Left":
            case "ArrowLeft":
              (this || _global).state.actions.ArrowLeft = !0;
              break;

            case "Delete":
            case "Backspace":
              if ((this || _global).state.editing) return;
              var s,
                  a,
                  n,
                  r = document.getSelection(),
                  d = "Delete" == t.key && r.anchorOffset == (r.anchorNode.length || 0),
                  h = r.anchorNode.previousSibling,
                  g = 1 == r.anchorNode.nodeType || !r.anchorOffset && h && 1 == h.nodeType && r.anchorNode.previousSibling,
                  p = o((this || _global).DOM.input.innerHTML),
                  c = this.getTagElms();
              if ("edit" == e.backspace && g) return s = 1 == r.anchorNode.nodeType ? null : r.anchorNode.previousElementSibling, setTimeout((this || _global).editTag.bind(this || _global), 0, s), void t.preventDefault();
              if (u() && g) return n = l(g), g.hasAttribute("readonly") || g.remove(), (this || _global).DOM.input.focus(), void setTimeout(() => {
                this.placeCaretAfterNode(n), (this || _global).DOM.input.click();
              });
              if ("BR" == r.anchorNode.nodeName) return;
              if ((d || g) && 1 == r.anchorNode.nodeType ? a = 0 == r.anchorOffset ? d ? c[0] : null : c[r.anchorOffset - 1] : d ? a = r.anchorNode.nextElementSibling : g && (a = g), 3 == r.anchorNode.nodeType && !r.anchorNode.nodeValue && r.anchorNode.previousElementSibling && t.preventDefault(), (g || d) && !e.backspace) return void t.preventDefault();
              if ("Range" != r.type && !r.anchorOffset && r.anchorNode == (this || _global).DOM.input && "Delete" != t.key) return void t.preventDefault();
              if ("Range" != r.type && a && a.hasAttribute("readonly")) return void this.placeCaretAfterNode(l(a));
              clearTimeout(y), y = setTimeout(() => {
                var t = document.getSelection(),
                    e = o((this || _global).DOM.input.innerHTML),
                    i = !d && t.anchorNode.previousSibling;
                if (e.length >= p.length && i) if (v.call(this || _global, i) && !i.hasAttribute("readonly")) {
                  if (this.removeTags(i), this.fixFirefoxLastTagNoCaret(), 2 == (this || _global).DOM.input.children.length && "BR" == (this || _global).DOM.input.children[1].tagName) return (this || _global).DOM.input.innerHTML = "", (this || _global).value.length = 0, !0;
                } else i.remove();
                (this || _global).value = [].map.call(c, (t, e) => {
                  var i = this.tagData(t);
                  if (t.parentNode || i.readonly) return i;
                  this.trigger("remove", {
                    tag: t,
                    index: e,
                    data: i
                  });
                }).filter(t => t);
              }, 20);
          }

          return !0;
        }

        switch (t.key) {
          case "Backspace":
            "select" == e.mode && e.enforceWhitelist && (this || _global).value.length ? this.removeTags() : (this || _global).state.dropdown.visible && "manual" != e.dropdown.position || "" != t.target.textContent && 8203 != i.charCodeAt(0) || (!0 === e.backspace ? this.removeTags() : "edit" == e.backspace && setTimeout((this || _global).editTag.bind(this || _global), 0));
            break;

          case "Esc":
          case "Escape":
            if ((this || _global).state.dropdown.visible) return;
            t.target.blur();
            break;

          case "Down":
          case "ArrowDown":
            (this || _global).state.dropdown.visible || (this || _global).dropdown.show();
            break;

          case "ArrowRight":
            {
              let t = (this || _global).state.inputSuggestion || (this || _global).state.ddItemData;
              if (t && e.autoComplete.rightKey) return void this.addTags([t], !0);
              break;
            }

          case "Tab":
            {
              let s = "select" == e.mode;
              if (!i || s) return !0;
              t.preventDefault();
            }

          case "Enter":
            if ((this || _global).state.dropdown.visible || 229 == t.keyCode) return;
            t.preventDefault(), setTimeout(() => {
              (this || _global).state.actions.selectOption || this.addTags(i, !0);
            });
        }
      },

      onInput(t) {
        if (this.postUpdate(), "mix" == (this || _global).settings.mode) return (this || _global).events.callbacks.onMixTagsInput.call(this || _global, t);

        var e = (this || _global).input.normalize.call(this || _global),
            i = e.length >= (this || _global).settings.dropdown.enabled,
            s = {
          value: e,
          inputElm: (this || _global).DOM.input
        };

        s.isValid = this.validateTag({
          value: e
        }), (this || _global).state.inputText != e && ((this || _global).input.set.call(this || _global, e, !1), -1 != e.search((this || _global).settings.delimiters) ? this.addTags(e) && (this || _global).input.set.call(this || _global) : (this || _global).settings.dropdown.enabled >= 0 && (this || _global).dropdown[i ? "show" : "hide"](e), this.trigger("input", s));
      },

      onMixTagsInput(t) {
        var e,
            i,
            s,
            a,
            n,
            o,
            r,
            l,
            d = (this || _global).settings,
            h = (this || _global).value.length,
            p = this.getTagElms(),
            c = document.createDocumentFragment(),
            m = window.getSelection().getRangeAt(0),
            v = [].map.call(p, t => this.tagData(t).value);
        if ("deleteContentBackward" == t.inputType && u() && (this || _global).events.callbacks.onKeydown.call(this || _global, {
          target: t.target,
          key: "Backspace"
        }), (this || _global).value.slice().forEach(t => {
          t.readonly && !v.includes(t.value) && c.appendChild(this.createTagElem(t));
        }), c.childNodes.length && (m.insertNode(c), this.setRangeAtStartEnd(!1, c.lastChild)), p.length != h) return (this || _global).value = [].map.call(this.getTagElms(), t => this.tagData(t)), void this.update({
          withoutChangeEvent: !0
        });
        if (this.hasMaxTags()) return !0;

        if (window.getSelection && (o = window.getSelection()).rangeCount > 0 && 3 == o.anchorNode.nodeType) {
          if ((m = o.getRangeAt(0).cloneRange()).collapse(!0), m.setStart(o.focusNode, 0), s = (e = m.toString().slice(0, m.endOffset)).split(d.pattern).length - 1, (i = e.match(d.pattern)) && (a = e.slice(e.lastIndexOf(i[i.length - 1]))), a) {
            if ((this || _global).state.actions.ArrowLeft = !1, (this || _global).state.tag = {
              prefix: a.match(d.pattern)[0],
              value: a.replace(d.pattern, "")
            }, (this || _global).state.tag.baseOffset = o.baseOffset - (this || _global).state.tag.value.length, l = (this || _global).state.tag.value.match(d.delimiters)) return (this || _global).state.tag.value = (this || _global).state.tag.value.replace(d.delimiters, ""), (this || _global).state.tag.delimiters = l[0], this.addTags((this || _global).state.tag.value, d.dropdown.clearOnSelect), void (this || _global).dropdown.hide();
            n = (this || _global).state.tag.value.length >= d.dropdown.enabled;

            try {
              r = (r = (this || _global).state.flaggedTags[(this || _global).state.tag.baseOffset]).prefix == (this || _global).state.tag.prefix && r.value[0] == (this || _global).state.tag.value[0], (this || _global).state.flaggedTags[(this || _global).state.tag.baseOffset] && !(this || _global).state.tag.value && delete (this || _global).state.flaggedTags[(this || _global).state.tag.baseOffset];
            } catch (t) {}

            (r || s < (this || _global).state.mixMode.matchedPatternCount) && (n = !1);
          } else (this || _global).state.flaggedTags = {};

          (this || _global).state.mixMode.matchedPatternCount = s;
        }

        setTimeout(() => {
          this.update({
            withoutChangeEvent: !0
          }), this.trigger("input", g({}, (this || _global).state.tag, {
            textContent: (this || _global).DOM.input.textContent
          })), (this || _global).state.tag && (this || _global).dropdown[n ? "show" : "hide"]((this || _global).state.tag.value);
        }, 10);
      },

      onInputIE(t) {
        var e = this || _global;
        setTimeout(function () {
          e.events.callbacks.onInput.call(e, t);
        });
      },

      observeOriginalInputValue() {
        (this || _global).DOM.originalInput.value != (this || _global).DOM.originalInput.tagifyValue && this.loadOriginalValues();
      },

      onClickScope(t) {
        var e = (this || _global).settings,
            i = t.target.closest("." + e.classNames.tag),
            s = +new Date() - (this || _global).state.hasFocus;

        if (t.target != (this || _global).DOM.scope) {
          if (!t.target.classList.contains(e.classNames.tagX)) return i ? (this.trigger("click", {
            tag: i,
            index: this.getNodeIndex(i),
            data: this.tagData(i),
            originalEvent: this.cloneEvent(t)
          }), void (1 !== e.editTags && 1 !== e.editTags.clicks || (this || _global).events.callbacks.onDoubleClickScope.call(this || _global, t))) : void (t.target == (this || _global).DOM.input && ("mix" == e.mode && this.fixFirefoxLastTagNoCaret(), s > 500) ? (this || _global).state.dropdown.visible ? (this || _global).dropdown.hide() : 0 === e.dropdown.enabled && "mix" != e.mode && (this || _global).dropdown.show((this || _global).value.length ? "" : void 0) : "select" == e.mode && !(this || _global).state.dropdown.visible && (this || _global).dropdown.show());
          this.removeTags(t.target.parentNode);
        } else (this || _global).state.hasFocus || (this || _global).DOM.input.focus();
      },

      onPaste(t) {
        t.preventDefault();
        var e,
            i,
            s = (this || _global).settings;
        if ("select" == s.mode && s.enforceWhitelist || !s.userInput) return !1;
        s.readonly || (e = t.clipboardData || window.clipboardData, i = e.getData("Text"), s.hooks.beforePaste(t, {
          tagify: this || _global,
          pastedText: i,
          clipboardData: e
        }).then(e => {
          void 0 === e && (e = i), e && (this.injectAtCaret(e, window.getSelection().getRangeAt(0)), "mix" == (this || _global).settings.mode ? (this || _global).events.callbacks.onMixTagsInput.call(this || _global, t) : (this || _global).settings.pasteAsTags ? this.addTags((this || _global).state.inputText + e, !0) : (this || _global).state.inputText = e);
        }).catch(t => t));
      },

      onDrop(t) {
        t.preventDefault();
      },

      onEditTagInput(t, e) {
        var i = t.closest("." + (this || _global).settings.classNames.tag),
            s = this.getNodeIndex(i),
            a = this.tagData(i),
            n = (this || _global).input.normalize.call(this || _global, t),
            o = i.innerHTML != i.__tagifyTagData.__originalHTML,
            r = this.validateTag(g(i.__tagifyTagData, {
          [(this || _global).settings.tagTextProp]: n
        }));

        o || !0 !== t.originalIsValid || (r = !0), i.classList.toggle((this || _global).settings.classNames.tagInvalid, !0 !== r), a.__isValid = r, i.title = !0 === r ? a.title || a.value : r, n.length >= (this || _global).settings.dropdown.enabled && ((this || _global).state.editing && ((this || _global).state.editing.value = n), (this || _global).dropdown.show(n)), this.trigger("edit:input", {
          tag: i,
          index: s,
          data: g({}, (this || _global).value[s], {
            newValue: n
          }),
          originalEvent: this.cloneEvent(e)
        });
      },

      onEditTagFocus(t) {
        (this || _global).state.editing = {
          scope: t,
          input: t.querySelector("[contenteditable]")
        };
      },

      onEditTagBlur(t) {
        if ((this || _global).state.hasFocus || this.toggleFocusClass(), (this || _global).DOM.scope.contains(t)) {
          var e,
              i,
              s = (this || _global).settings,
              a = t.closest("." + s.classNames.tag),
              n = (this || _global).input.normalize.call(this || _global, t),
              o = this.tagData(a).__originalData,
              r = a.innerHTML != a.__tagifyTagData.__originalHTML,
              l = this.validateTag({
            [s.tagTextProp]: n
          });

          if (n) {
            if (r) {
              if (e = this.hasMaxTags(), i = g({}, o, {
                [s.tagTextProp]: this.trim(n),
                value: n,
                __isValid: l
              }), s.transformTag.call(this || _global, i, o), !0 !== (l = (!e || !0 === o.__isValid) && this.validateTag(i))) {
                if (this.trigger("invalid", {
                  data: i,
                  tag: a,
                  message: l
                }), s.editTags.keepInvalid) return;
                s.keepInvalidTags ? i.__isValid = l : i = o;
              } else s.keepInvalidTags && (delete i.title, delete i["aria-invalid"], delete i.class);

              this.onEditTagDone(a, i);
            } else this.onEditTagDone(a, o);
          } else this.onEditTagDone(a);
        }
      },

      onEditTagkeydown(t, e) {
        switch (this.trigger("edit:keydown", {
          originalEvent: this.cloneEvent(t)
        }), t.key) {
          case "Esc":
          case "Escape":
            e.innerHTML = e.__tagifyTagData.__originalHTML;

          case "Enter":
          case "Tab":
            t.preventDefault(), t.target.blur();
        }
      },

      onDoubleClickScope(t) {
        var e,
            i,
            s = t.target.closest("." + (this || _global).settings.classNames.tag),
            a = (this || _global).settings;
        s && a.userInput && (e = s.classList.contains((this || _global).settings.classNames.tagEditing), i = s.hasAttribute("readonly"), "select" == a.mode || a.readonly || e || i || !(this || _global).settings.editTags || this.editTag(s), this.toggleFocusClass(!0), this.trigger("dblclick", {
          tag: s,
          index: this.getNodeIndex(s),
          data: this.tagData(s)
        }));
      },

      onInputDOMChange(t) {
        t.forEach(t => {
          t.addedNodes.forEach(t => {
            if (t) if ("<div><br></div>" == t.outerHTML) t.replaceWith(document.createElement("br"));else if (1 == t.nodeType && t.querySelector((this || _global).settings.classNames.tagSelector)) {
              let e = document.createTextNode("");
              3 == t.childNodes[0].nodeType && "BR" != t.previousSibling.nodeName && (e = document.createTextNode("\n")), t.replaceWith(e, ...[...t.childNodes].slice(0, -1)), this.placeCaretAfterNode(e.previousSibling);
            } else v.call(this || _global, t) && t.previousSibling && "BR" == t.previousSibling.nodeName && (t.previousSibling.replaceWith("\n\u200B"), this.placeCaretAfterNode(t.previousSibling.previousSibling));
          }), t.removedNodes.forEach(t => {
            t && "BR" == t.nodeName && v.call(this || _global, e) && (this.removeTags(e), this.fixFirefoxLastTagNoCaret());
          });
        });
        var e = (this || _global).DOM.input.lastChild;
        e && "" == e.nodeValue && e.remove(), e && "BR" == e.nodeName || (this || _global).DOM.input.appendChild(document.createElement("br"));
      }

    }
  };

  function M(t, e) {
    if (!t) {
      console.warn("Tagify:", "input element not found", t);
      const e = new Proxy(this || _global, {
        get: () => () => e
      });
      return e;
    }

    if (t.previousElementSibling && t.previousElementSibling.classList.contains("tagify")) return console.warn("Tagify: ", "input element is already Tagified", t), this || _global;
    var i;
    g(this || _global, function (t) {
      var e = document.createTextNode("");

      function i(t, i, s) {
        s && i.split(/\s+/g).forEach(i => e[t + "EventListener"].call(e, i, s));
      }

      return {
        off(t, e) {
          return i("remove", t, e), this || _global;
        },

        on(t, e) {
          return e && "function" == typeof e && i("add", t, e), this || _global;
        },

        trigger(i, s, a) {
          var n;
          if (a = a || {
            cloneData: !0
          }, i) if (t.settings.isJQueryPlugin) "remove" == i && (i = "removeTag"), jQuery(t.DOM.originalInput).triggerHandler(i, [s]);else {
            try {
              var o = "object" == typeof s ? s : {
                value: s
              };
              if ((o = a.cloneData ? g({}, o) : o).tagify = this || _global, s instanceof Object) for (var r in s) s[r] instanceof HTMLElement && (o[r] = s[r]);
              n = new CustomEvent(i, {
                detail: o
              });
            } catch (t) {
              console.warn(t);
            }

            e.dispatchEvent(n);
          }
        }

      };
    }(this || _global)), (this || _global).isFirefox = "undefined" != typeof InstallTrigger, (this || _global).isIE = window.document.documentMode, e = e || {}, (this || _global).getPersistedData = (i = e.id, t => {
      let e,
          s = "/" + t;
      if (1 == localStorage.getItem(b + i + "/v", 1)) try {
        e = JSON.parse(localStorage[b + i + s]);
      } catch (t) {}
      return e;
    }), (this || _global).setPersistedData = (t => t ? (localStorage.setItem(b + t + "/v", 1), (e, i) => {
      let s = "/" + i,
          a = JSON.stringify(e);
      e && i && (localStorage.setItem(b + t + s, a), dispatchEvent(new Event("storage")));
    }) : () => {})(e.id), (this || _global).clearPersistedData = (t => e => {
      const i = b + "/" + t + "/";
      if (e) localStorage.removeItem(i + e);else for (let t in localStorage) t.includes(i) && localStorage.removeItem(t);
    })(e.id), this.applySettings(t, e), (this || _global).state = {
      inputText: "",
      editing: !1,
      actions: {},
      mixMode: {},
      dropdown: {},
      flaggedTags: {}
    }, (this || _global).value = [], (this || _global).listeners = {}, (this || _global).DOM = {}, this.build(t), T.call(this || _global), this.getCSSVars(), this.loadOriginalValues(), (this || _global).events.customBinding.call(this || _global), (this || _global).events.binding.call(this || _global), t.autofocus && (this || _global).DOM.input.focus();
  }

  return M.prototype = {
    _dropdown: w,
    helpers: {
      sameStr: s,
      removeCollectionProp: a,
      omit: n,
      isObject: h,
      parseHTML: r,
      escapeHTML: d,
      extend: g,
      concatWithoutDups: p,
      getUID: m,
      isNodeTag: v
    },
    customEventsList: ["change", "add", "remove", "invalid", "input", "click", "keydown", "focus", "blur", "edit:input", "edit:beforeUpdate", "edit:updated", "edit:start", "edit:keydown", "dropdown:show", "dropdown:hide", "dropdown:select", "dropdown:updated", "dropdown:noMatch", "dropdown:scroll"],
    dataProps: ["__isValid", "__removed", "__originalData", "__originalHTML", "__tagId"],

    trim(t) {
      return (this || _global).settings.trim && t && "string" == typeof t ? t.trim() : t;
    },

    parseHTML: r,
    templates: D,

    parseTemplate(t, e) {
      return t = (this || _global).settings.templates[t] || t, this.parseHTML(t.apply(this || _global, e));
    },

    set whitelist(t) {
      const e = t && Array.isArray(t);
      (this || _global).settings.whitelist = e ? t : [], this.setPersistedData(e ? t : [], "whitelist");
    },

    get whitelist() {
      return (this || _global).settings.whitelist;
    },

    applySettings(t, i) {
      f.templates = (this || _global).templates;
      var s = (this || _global).settings = g({}, f, i);
      s.disabled = t.hasAttribute("disabled"), s.readonly = s.readonly || t.hasAttribute("readonly"), s.placeholder = d(t.getAttribute("placeholder") || s.placeholder || ""), s.required = t.hasAttribute("required");

      for (let t in s.classNames) Object.defineProperty(s.classNames, t + "Selector", {
        get() {
          return "." + (this || _global)[t].split(" ")[0];
        }

      });

      if ((this || _global).isIE && (s.autoComplete = !1), ["whitelist", "blacklist"].forEach(e => {
        var i = t.getAttribute("data-" + e);
        i && (i = i.split(s.delimiters)) instanceof Array && (s[e] = i);
      }), "autoComplete" in i && !h(i.autoComplete) && (s.autoComplete = f.autoComplete, s.autoComplete.enabled = i.autoComplete), "mix" == s.mode && (s.autoComplete.rightKey = !0, s.delimiters = i.delimiters || null, s.tagTextProp && !s.dropdown.searchKeys.includes(s.tagTextProp) && s.dropdown.searchKeys.push(s.tagTextProp)), t.pattern) try {
        s.pattern = new RegExp(t.pattern);
      } catch (t) {}
      if ((this || _global).settings.delimiters) try {
        s.delimiters = new RegExp((this || _global).settings.delimiters, "g");
      } catch (t) {}
      s.disabled && (s.userInput = !1), (this || _global).TEXTS = e(e({}, x), s.texts || {}), "select" != s.mode && s.userInput || (s.dropdown.enabled = 0), s.dropdown.appendTarget = i.dropdown && i.dropdown.appendTarget ? i.dropdown.appendTarget : document.body;
      let a = this.getPersistedData("whitelist");
      Array.isArray(a) && ((this || _global).whitelist = Array.isArray(s.whitelist) ? p(s.whitelist, a) : a);
    },

    getAttributes(t) {
      var e,
          i = this.getCustomAttributes(t),
          s = "";

      for (e in i) s += " " + e + (void 0 !== t[e] ? `="${i[e]}"` : "");

      return s;
    },

    getCustomAttributes(t) {
      if (!h(t)) return "";
      var e,
          i = {};

      for (e in t) "__" != e.slice(0, 2) && "class" != e && t.hasOwnProperty(e) && void 0 !== t[e] && (i[e] = d(t[e]));

      return i;
    },

    setStateSelection() {
      var t = window.getSelection(),
          e = {
        anchorOffset: t.anchorOffset,
        anchorNode: t.anchorNode,
        range: t.getRangeAt && t.rangeCount && t.getRangeAt(0)
      };
      return (this || _global).state.selection = e, e;
    },

    getCaretGlobalPosition() {
      const t = document.getSelection();

      if (t.rangeCount) {
        const e = t.getRangeAt(0),
              i = e.startContainer,
              s = e.startOffset;
        let a, n;
        if (s > 0) return n = document.createRange(), n.setStart(i, s - 1), n.setEnd(i, s), a = n.getBoundingClientRect(), {
          left: a.right,
          top: a.top,
          bottom: a.bottom
        };
        if (i.getBoundingClientRect) return i.getBoundingClientRect();
      }

      return {
        left: -9999,
        top: -9999
      };
    },

    getCSSVars() {
      var t = getComputedStyle((this || _global).DOM.scope, null);
      var e;
      (this || _global).CSSVars = {
        tagHideTransition: (({
          value: t,
          unit: e
        }) => "s" == e ? 1000 * t : t)(function (t) {
          if (!t) return {};
          var e = (t = t.trim().split(" ")[0]).split(/\d+/g).filter(t => t).pop().trim();
          return {
            value: +t.split(e).filter(t => t)[0].trim(),
            unit: e
          };
        }((e = "tag-hide-transition", t.getPropertyValue("--" + e))))
      };
    },

    build(t) {
      var e = (this || _global).DOM;
      (this || _global).settings.mixMode.integrated ? (e.originalInput = null, e.scope = t, e.input = t) : (e.originalInput = t, e.originalInput_tabIndex = t.tabIndex, e.scope = this.parseTemplate("wrapper", [t, (this || _global).settings]), e.input = e.scope.querySelector((this || _global).settings.classNames.inputSelector), t.parentNode.insertBefore(e.scope, t), t.tabIndex = -1);
    },

    destroy() {
      (this || _global).events.unbindGlobal.call(this || _global), (this || _global).DOM.scope.parentNode.removeChild((this || _global).DOM.scope), (this || _global).DOM.originalInput.tabIndex = (this || _global).DOM.originalInput_tabIndex, (this || _global).dropdown.hide(!0), clearTimeout((this || _global).dropdownHide__bindEventsTimeout);
    },

    loadOriginalValues(t) {
      var e,
          i = (this || _global).settings;

      if ((this || _global).state.blockChangeEvent = !0, void 0 === t) {
        const e = this.getPersistedData("value");
        t = e && !(this || _global).DOM.originalInput.value ? e : i.mixMode.integrated ? (this || _global).DOM.input.textContent : (this || _global).DOM.originalInput.value;
      }

      if (this.removeAllTags(), t) {
        if ("mix" == i.mode) this.parseMixTags(this.trim(t)), (e = (this || _global).DOM.input.lastChild) && "BR" == e.tagName || (this || _global).DOM.input.insertAdjacentHTML("beforeend", "<br>");else {
          try {
            JSON.parse(t) instanceof Array && (t = JSON.parse(t));
          } catch (t) {}

          this.addTags(t).forEach(t => t && t.classList.add(i.classNames.tagNoAnimation));
        }
      } else this.postUpdate();
      (this || _global).state.lastOriginalValueReported = i.mixMode.integrated ? "" : (this || _global).DOM.originalInput.value, (this || _global).state.blockChangeEvent = !1;
    },

    cloneEvent(t) {
      var e = {};

      for (var i in t) e[i] = t[i];

      return e;
    },

    loading(t) {
      return (this || _global).state.isLoading = t, (this || _global).DOM.scope.classList[t ? "add" : "remove"]((this || _global).settings.classNames.scopeLoading), this || _global;
    },

    tagLoading(t, e) {
      return t && t.classList[e ? "add" : "remove"]((this || _global).settings.classNames.tagLoading), this || _global;
    },

    toggleClass(t, e) {
      "string" == typeof t && (this || _global).DOM.scope.classList.toggle(t, e);
    },

    toggleFocusClass(t) {
      this.toggleClass((this || _global).settings.classNames.focus, !!t);
    },

    triggerChangeEvent: function () {
      if (!(this || _global).settings.mixMode.integrated) {
        var t = (this || _global).DOM.originalInput,
            e = (this || _global).state.lastOriginalValueReported !== t.value,
            i = new CustomEvent("change", {
          bubbles: !0
        });
        e && ((this || _global).state.lastOriginalValueReported = t.value, i.simulated = !0, t._valueTracker && t._valueTracker.setValue(Math.random()), t.dispatchEvent(i), this.trigger("change", (this || _global).state.lastOriginalValueReported), t.value = (this || _global).state.lastOriginalValueReported);
      }
    },
    events: O,

    fixFirefoxLastTagNoCaret() {},

    placeCaretAfterNode(t) {
      if (t && t.parentNode) {
        var e = t.nextSibling,
            i = window.getSelection(),
            s = i.getRangeAt(0);
        i.rangeCount && (s.setStartAfter(e || t), s.collapse(!0), i.removeAllRanges(), i.addRange(s));
      }
    },

    insertAfterTag(t, e) {
      if (e = e || (this || _global).settings.mixMode.insertAfterTag, t && t.parentNode && e) return e = "string" == typeof e ? document.createTextNode(e) : e, t.parentNode.insertBefore(e, t.nextSibling), e;
    },

    editTag(t, e) {
      t = t || this.getLastTag(), e = e || {}, (this || _global).dropdown.hide();
      var i = (this || _global).settings;

      function s() {
        return t.querySelector(i.classNames.tagTextSelector);
      }

      var a = s(),
          n = this.getNodeIndex(t),
          o = this.tagData(t),
          r = (this || _global).events.callbacks,
          l = this || _global,
          d = !0;

      if (a) {
        if (!(o instanceof Object && "editable" in o) || o.editable) return a.setAttribute("contenteditable", !0), t.classList.add(i.classNames.tagEditing), this.tagData(t, {
          __originalData: g({}, o),
          __originalHTML: t.innerHTML
        }), a.addEventListener("focus", r.onEditTagFocus.bind(this || _global, t)), a.addEventListener("blur", function () {
          setTimeout(() => r.onEditTagBlur.call(l, s()));
        }), a.addEventListener("input", r.onEditTagInput.bind(this || _global, a)), a.addEventListener("keydown", e => r.onEditTagkeydown.call(this || _global, e, t)), a.focus(), this.setRangeAtStartEnd(!1, a), e.skipValidation || (d = this.editTagToggleValidity(t)), a.originalIsValid = d, this.trigger("edit:start", {
          tag: t,
          index: n,
          data: o,
          isValid: d
        }), this || _global;
      } else console.warn("Cannot find element in Tag template: .", i.classNames.tagTextSelector);
    },

    editTagToggleValidity(t, e) {
      var i;
      if (e = e || this.tagData(t)) return (i = !("__isValid" in e) || !0 === e.__isValid) || this.removeTagsFromValue(t), this.update(), t.classList.toggle((this || _global).settings.classNames.tagNotAllowed, !i), e.__isValid;
      console.warn("tag has no data: ", t, e);
    },

    onEditTagDone(t, e) {
      e = e || {};
      var i = {
        tag: t = t || (this || _global).state.editing.scope,
        index: this.getNodeIndex(t),
        previousData: this.tagData(t),
        data: e
      };
      this.trigger("edit:beforeUpdate", i, {
        cloneData: !1
      }), (this || _global).state.editing = !1, delete e.__originalData, delete e.__originalHTML, t && e[(this || _global).settings.tagTextProp] ? (t = this.replaceTag(t, e), this.editTagToggleValidity(t, e), (this || _global).settings.a11y.focusableTags ? t.focus() : this.placeCaretAfterNode(t.previousSibling)) : t && this.removeTags(t), this.trigger("edit:updated", i), (this || _global).dropdown.hide(), (this || _global).settings.keepInvalidTags && this.reCheckInvalidTags();
    },

    replaceTag(t, e) {
      e && e.value || (e = t.__tagifyTagData), e.__isValid && 1 != e.__isValid && g(e, this.getInvalidTagAttrs(e, e.__isValid));
      var i = this.createTagElem(e);
      return t.parentNode.replaceChild(i, t), this.updateValueByDOMTags(), i;
    },

    updateValueByDOMTags() {
      (this || _global).value.length = 0, [].forEach.call(this.getTagElms(), t => {
        t.classList.contains((this || _global).settings.classNames.tagNotAllowed.split(" ")[0]) || (this || _global).value.push(this.tagData(t));
      }), this.update();
    },

    setRangeAtStartEnd(t, e) {
      t = "number" == typeof t ? t : !!t, e = (e = e || (this || _global).DOM.input).lastChild || e;
      var i = document.getSelection();

      try {
        i.rangeCount >= 1 && ["Start", "End"].forEach(s => i.getRangeAt(0)["set" + s](e, t || e.length));
      } catch (t) {}
    },

    injectAtCaret(t, e) {
      if (e = e || (this || _global).state.selection.range) return "string" == typeof t && (t = document.createTextNode(t)), e.deleteContents(), e.insertNode(t), this.setRangeAtStartEnd(!1, t), this.updateValueByDOMTags(), this.update(), this || _global;
    },

    input: {
      set(t = "", e = !0) {
        var i = (this || _global).settings.dropdown.closeOnSelect;
        (this || _global).state.inputText = t, e && ((this || _global).DOM.input.innerHTML = d("" + t)), !t && i && (this || _global).dropdown.hide.bind(this || _global), (this || _global).input.autocomplete.suggest.call(this || _global), (this || _global).input.validate.call(this || _global);
      },

      raw() {
        return (this || _global).DOM.input.textContent;
      },

      validate() {
        var t = !(this || _global).state.inputText || !0 === this.validateTag({
          value: (this || _global).state.inputText
        });
        return (this || _global).DOM.input.classList.toggle((this || _global).settings.classNames.inputInvalid, !t), t;
      },

      normalize(t) {
        var e = t || (this || _global).DOM.input,
            i = [];
        e.childNodes.forEach(t => 3 == t.nodeType && i.push(t.nodeValue)), i = i.join("\n");

        try {
          i = i.replace(/(?:\r\n|\r|\n)/g, (this || _global).settings.delimiters.source.charAt(0));
        } catch (t) {}

        return i = i.replace(/\s/g, " "), (this || _global).settings.trim && (i = i.replace(/^\s+/, "")), this.trim(i);
      },

      autocomplete: {
        suggest(t) {
          if ((this || _global).settings.autoComplete.enabled) {
            "string" == typeof (t = t || {}) && (t = {
              value: t
            });
            var e = t.value ? "" + t.value : "",
                i = e.substr(0, (this || _global).state.inputText.length).toLowerCase(),
                s = e.substring((this || _global).state.inputText.length);
            e && (this || _global).state.inputText && i == (this || _global).state.inputText.toLowerCase() ? ((this || _global).DOM.input.setAttribute("data-suggest", s), (this || _global).state.inputSuggestion = t) : ((this || _global).DOM.input.removeAttribute("data-suggest"), delete (this || _global).state.inputSuggestion);
          }
        },

        set(t) {
          var e = (this || _global).DOM.input.getAttribute("data-suggest"),
              i = t || (e ? (this || _global).state.inputText + e : null);

          return !!i && ("mix" == (this || _global).settings.mode ? this.replaceTextWithNode(document.createTextNode((this || _global).state.tag.prefix + i)) : ((this || _global).input.set.call(this || _global, i), this.setRangeAtStartEnd()), (this || _global).input.autocomplete.suggest.call(this || _global), (this || _global).dropdown.hide(), !0);
        }

      }
    },

    getTagIdx(t) {
      return (this || _global).value.findIndex(e => e.__tagId == (t || {}).__tagId);
    },

    getNodeIndex(t) {
      var e = 0;
      if (t) for (; t = t.previousElementSibling;) e++;
      return e;
    },

    getTagElms(...t) {
      var e = "." + [...(this || _global).settings.classNames.tag.split(" "), ...t].join(".");
      return [].slice.call((this || _global).DOM.scope.querySelectorAll(e));
    },

    getLastTag() {
      var t = (this || _global).DOM.scope.querySelectorAll(`${(this || _global).settings.classNames.tagSelector}:not(.${(this || _global).settings.classNames.tagHide}):not([readonly])`);

      return t[t.length - 1];
    },

    tagData: (t, e, i) => t ? (e && (t.__tagifyTagData = i ? e : g({}, t.__tagifyTagData || {}, e)), t.__tagifyTagData) : (console.warn("tag element doesn't exist", t, e), e),

    isTagDuplicate(t, e, i) {
      var a = (this || _global).settings;
      return "select" != a.mode && (this || _global).value.reduce((n, o) => s(this.trim("" + t), o.value, e || a.dropdown.caseSensitive) && i != o.__tagId ? n + 1 : n, 0);
    },

    getTagIndexByValue(t) {
      var e = [];
      return this.getTagElms().forEach((i, a) => {
        s(this.trim(i.textContent), t, (this || _global).settings.dropdown.caseSensitive) && e.push(a);
      }), e;
    },

    getTagElmByValue(t) {
      var e = this.getTagIndexByValue(t)[0];
      return this.getTagElms()[e];
    },

    flashTag(t) {
      t && (t.classList.add((this || _global).settings.classNames.tagFlash), setTimeout(() => {
        t.classList.remove((this || _global).settings.classNames.tagFlash);
      }, 100));
    },

    isTagBlacklisted(t) {
      return t = this.trim(t.toLowerCase()), (this || _global).settings.blacklist.filter(e => ("" + e).toLowerCase() == t).length;
    },

    isTagWhitelisted(t) {
      return !!this.getWhitelistItem(t);
    },

    getWhitelistItem(t, e, i) {
      e = e || "value";
      var a,
          n = (this || _global).settings;
      return (i = i || n.whitelist).some(i => {
        var o = "string" == typeof i ? i : i[e] || i.value;
        if (s(o, t, n.dropdown.caseSensitive, n.trim)) return a = "string" == typeof i ? {
          value: i
        } : i, !0;
      }), a || "value" != e || "value" == n.tagTextProp || (a = this.getWhitelistItem(t, n.tagTextProp, i)), a;
    },

    validateTag(t) {
      var e = (this || _global).settings,
          i = "value" in t ? "value" : e.tagTextProp,
          s = this.trim(t[i] + "");
      return (t[i] + "").trim() ? e.pattern && e.pattern instanceof RegExp && !e.pattern.test(s) ? (this || _global).TEXTS.pattern : !e.duplicates && this.isTagDuplicate(s, (this || _global).state.editing, t.__tagId) ? (this || _global).TEXTS.duplicate : this.isTagBlacklisted(s) || e.enforceWhitelist && !this.isTagWhitelisted(s) ? (this || _global).TEXTS.notAllowed : !e.validate || e.validate(t) : (this || _global).TEXTS.empty;
    },

    getInvalidTagAttrs(t, e) {
      return {
        "aria-invalid": !0,
        class: `${t.class || ""} ${(this || _global).settings.classNames.tagNotAllowed}`.trim(),
        title: e
      };
    },

    hasMaxTags() {
      return (this || _global).value.length >= (this || _global).settings.maxTags && (this || _global).TEXTS.exceed;
    },

    setReadonly(t, e) {
      var i = (this || _global).settings;
      document.activeElement.blur(), i[e || "readonly"] = t, (this || _global).DOM.scope[(t ? "set" : "remove") + "Attribute"](e || "readonly", !0), this.setContentEditable(!t);
    },

    setContentEditable(t) {
      !(this || _global).settings.readonly && (this || _global).settings.userInput && ((this || _global).DOM.input.contentEditable = t, (this || _global).DOM.input.tabIndex = t ? 0 : -1);
    },

    setDisabled(t) {
      this.setReadonly(t, "disabled");
    },

    normalizeTags(t) {
      var e = (this || _global).settings,
          i = e.whitelist,
          s = e.delimiters,
          a = e.mode,
          n = e.tagTextProp;
      e.enforceWhitelist;

      var o = [],
          r = !!i && i[0] instanceof Object,
          l = t instanceof Array,
          d = t => (t + "").split(s).filter(t => t).map(t => ({
        [n]: this.trim(t),
        value: this.trim(t)
      }));

      if ("number" == typeof t && (t = t.toString()), "string" == typeof t) {
        if (!t.trim()) return [];
        t = d(t);
      } else l && (t = [].concat(...t.map(t => t.value ? t : d(t))));

      return r && (t.forEach(t => {
        var e = o.map(t => t.value),
            i = (this || _global).dropdown.filterListItems.call(this || _global, t[n], {
          exact: !0
        });

        (this || _global).settings.duplicates || (i = i.filter(t => !e.includes(t.value)));
        var s = i.length > 1 ? this.getWhitelistItem(t[n], n, i) : i[0];
        s && s instanceof Object ? o.push(s) : "mix" != a && (null == t.value && (t.value = t[n]), o.push(t));
      }), o.length && (t = o)), t;
    },

    parseMixTags(t) {
      var e = (this || _global).settings,
          i = e.mixTagsInterpolator,
          s = e.duplicates,
          a = e.transformTag,
          n = e.enforceWhitelist,
          o = e.maxTags,
          r = e.tagTextProp,
          l = [];
      return t = t.split(i[0]).map((t, e) => {
        var d,
            h,
            g,
            p = t.split(i[1]),
            c = p[0],
            u = l.length == o;

        try {
          if (c == +c) throw Error;
          h = JSON.parse(c);
        } catch (t) {
          h = this.normalizeTags(c)[0] || {
            value: c
          };
        }

        if (a.call(this || _global, h), u || !(p.length > 1) || n && !this.isTagWhitelisted(h.value) || !s && this.isTagDuplicate(h.value)) {
          if (t) return e ? i[0] + t : t;
        } else h[d = h[r] ? r : "value"] = this.trim(h[d]), g = this.createTagElem(h), l.push(h), g.classList.add((this || _global).settings.classNames.tagNoAnimation), p[0] = g.outerHTML, (this || _global).value.push(h);

        return p.join("");
      }).join(""), (this || _global).DOM.input.innerHTML = t, (this || _global).DOM.input.appendChild(document.createTextNode("")), (this || _global).DOM.input.normalize(), this.getTagElms().forEach((t, e) => this.tagData(t, l[e])), this.update({
        withoutChangeEvent: !0
      }), t;
    },

    replaceTextWithNode(t, e) {
      if ((this || _global).state.tag || e) {
        e = e || (this || _global).state.tag.prefix + (this || _global).state.tag.value;
        var i,
            s,
            a = window.getSelection(),
            n = a.anchorNode,
            o = (this || _global).state.tag.delimiters ? (this || _global).state.tag.delimiters.length : 0;
        return n.splitText(a.anchorOffset - o), -1 == (i = n.nodeValue.lastIndexOf(e)) ? !0 : (s = n.splitText(i), t && n.parentNode.replaceChild(t, s), !0);
      }
    },

    selectTag(t, e) {
      var i = (this || _global).settings;

      if (!i.enforceWhitelist || this.isTagWhitelisted(e.value)) {
        (this || _global).input.set.call(this || _global, e[i.tagTextProp] || e.value, !0), (this || _global).state.actions.selectOption && setTimeout((this || _global).setRangeAtStartEnd.bind(this || _global));
        var s = this.getLastTag();
        return s ? this.replaceTag(s, e) : this.appendTag(t), (this || _global).value[0] = e, this.update(), this.trigger("add", {
          tag: t,
          data: e
        }), [t];
      }
    },

    addEmptyTag(t) {
      var e = g({
        value: ""
      }, t || {}),
          i = this.createTagElem(e);
      this.tagData(i, e), this.appendTag(i), this.editTag(i, {
        skipValidation: !0
      });
    },

    addTags(t, e, i) {
      var s = [],
          a = (this || _global).settings,
          n = document.createDocumentFragment();
      return i = i || a.skipInvalid, t && 0 != t.length ? (t = this.normalizeTags(t), "mix" == a.mode ? this.addMixTags(t) : ("select" == a.mode && (e = !1), (this || _global).DOM.input.removeAttribute("style"), t.forEach(t => {
        var e,
            o = {},
            r = Object.assign({}, t, {
          value: t.value + ""
        });

        if (t = Object.assign({}, r), a.transformTag.call(this || _global, t), t.__isValid = this.hasMaxTags() || this.validateTag(t), !0 !== t.__isValid) {
          if (i) return;
          g(o, this.getInvalidTagAttrs(t, t.__isValid), {
            __preInvalidData: r
          }), t.__isValid == (this || _global).TEXTS.duplicate && this.flashTag(this.getTagElmByValue(t.value));
        }

        if ("readonly" in t && (t.readonly ? o["aria-readonly"] = !0 : delete t.readonly), e = this.createTagElem(t, o), s.push(e), "select" == a.mode) return this.selectTag(e, t);
        n.appendChild(e), t.__isValid && !0 === t.__isValid ? ((this || _global).value.push(t), this.trigger("add", {
          tag: e,
          index: (this || _global).value.length - 1,
          data: t
        })) : (this.trigger("invalid", {
          data: t,
          index: (this || _global).value.length,
          tag: e,
          message: t.__isValid
        }), a.keepInvalidTags || setTimeout(() => this.removeTags(e, !0), 1000)), (this || _global).dropdown.position();
      }), this.appendTag(n), this.update(), t.length && e && (this || _global).input.set.call(this || _global), (this || _global).dropdown.refilter(), s)) : ("select" == a.mode && this.removeAllTags(), s);
    },

    addMixTags(t) {
      if ((t = this.normalizeTags(t))[0].prefix || (this || _global).state.tag) return this.prefixedTextToTag(t[0]);
      "string" == typeof t && (t = [{
        value: t
      }]);
      var e = !!(this || _global).state.selection,
          i = document.createDocumentFragment();
      return t.forEach(t => {
        var e = this.createTagElem(t);
        i.appendChild(e), this.insertAfterTag(e);
      }), e ? this.injectAtCaret(i) : ((this || _global).DOM.input.focus(), (e = this.setStateSelection()).range.setStart((this || _global).DOM.input, e.range.endOffset), e.range.setEnd((this || _global).DOM.input, e.range.endOffset), (this || _global).DOM.input.appendChild(i), this.updateValueByDOMTags(), this.update()), i;
    },

    prefixedTextToTag(t) {
      var e,
          i = (this || _global).settings,
          s = (this || _global).state.tag.delimiters;

      if (i.transformTag.call(this || _global, t), t.prefix = t.prefix || (this || _global).state.tag ? (this || _global).state.tag.prefix : (i.pattern.source || i.pattern)[0], e = this.createTagElem(t), this.replaceTextWithNode(e) || (this || _global).DOM.input.appendChild(e), setTimeout(() => e.classList.add((this || _global).settings.classNames.tagNoAnimation), 300), (this || _global).value.push(t), this.update(), !s) {
        var a = this.insertAfterTag(e) || e;
        this.placeCaretAfterNode(a);
      }

      return (this || _global).state.tag = null, this.trigger("add", g({}, {
        tag: e
      }, {
        data: t
      })), e;
    },

    appendTag(t) {
      var e = (this || _global).DOM,
          i = e.input;
      i === e.input ? e.scope.insertBefore(t, i) : e.scope.appendChild(t);
    },

    createTagElem(t, i) {
      t.__tagId = m();
      var s,
          a = g({}, t, e({
        value: d(t.value + "")
      }, i));
      return function (t) {
        for (var e, i = document.createNodeIterator(t, NodeFilter.SHOW_TEXT, null, !1); e = i.nextNode();) e.textContent.trim() || e.parentNode.removeChild(e);
      }(s = this.parseTemplate("tag", [a])), this.tagData(s, t), s;
    },

    reCheckInvalidTags() {
      var t = (this || _global).settings;
      this.getTagElms(t.classNames.tagNotAllowed).forEach((t, e) => {
        var i = this.tagData(t),
            s = this.hasMaxTags(),
            a = this.validateTag(i);
        if (!0 === a && !s) return i = i.__preInvalidData ? i.__preInvalidData : {
          value: i.value
        }, this.replaceTag(t, i);
        t.title = s || a;
      });
    },

    removeTags(t, e, i) {
      var s;
      if (t = t && t instanceof HTMLElement ? [t] : t instanceof Array ? t : t ? [t] : [this.getLastTag()], s = t.reduce((t, e) => {
        e && "string" == typeof e && (e = this.getTagElmByValue(e));
        var i = this.tagData(e);
        return e && i && !i.readonly && t.push({
          node: e,
          idx: this.getTagIdx(i),
          data: this.tagData(e, {
            __removed: !0
          })
        }), t;
      }, []), i = "number" == typeof i ? i : (this || _global).CSSVars.tagHideTransition, "select" == (this || _global).settings.mode && (i = 0, (this || _global).input.set.call(this || _global)), 1 == s.length && s[0].node.classList.contains((this || _global).settings.classNames.tagNotAllowed) && (e = !0), s.length) return (this || _global).settings.hooks.beforeRemoveTag(s, {
        tagify: this || _global
      }).then(() => {
        function t(t) {
          t.node.parentNode && (t.node.parentNode.removeChild(t.node), e ? (this || _global).settings.keepInvalidTags && this.trigger("remove", {
            tag: t.node,
            index: t.idx
          }) : (this.trigger("remove", {
            tag: t.node,
            index: t.idx,
            data: t.data
          }), (this || _global).dropdown.refilter(), (this || _global).dropdown.position(), (this || _global).DOM.input.normalize(), (this || _global).settings.keepInvalidTags && this.reCheckInvalidTags()));
        }

        i && i > 10 && 1 == s.length ? function (e) {
          e.node.style.width = parseFloat(window.getComputedStyle(e.node).width) + "px", document.body.clientTop, e.node.classList.add((this || _global).settings.classNames.tagHide), setTimeout(t.bind(this || _global), i, e);
        }.call(this || _global, s[0]) : s.forEach(t.bind(this || _global)), e || (this.removeTagsFromValue(s.map(t => t.node)), this.update(), "select" == (this || _global).settings.mode && this.setContentEditable(!0));
      }).catch(t => {});
    },

    removeTagsFromDOM() {
      [].slice.call(this.getTagElms()).forEach(t => t.parentNode.removeChild(t));
    },

    removeTagsFromValue(t) {
      (t = Array.isArray(t) ? t : [t]).forEach(t => {
        var e = this.tagData(t),
            i = this.getTagIdx(e);
        i > -1 && (this || _global).value.splice(i, 1);
      });
    },

    removeAllTags(t) {
      t = t || {}, (this || _global).value = [], "mix" == (this || _global).settings.mode ? (this || _global).DOM.input.innerHTML = "" : this.removeTagsFromDOM(), (this || _global).dropdown.position(), "select" == (this || _global).settings.mode && ((this || _global).input.set.call(this || _global), this.setContentEditable(!0)), this.update(t);
    },

    postUpdate() {
      var t = (this || _global).settings.classNames,
          e = "mix" == (this || _global).settings.mode ? (this || _global).settings.mixMode.integrated ? (this || _global).DOM.input.textContent : (this || _global).DOM.originalInput.value.trim() : (this || _global).value.length + (this || _global).input.raw.call(this || _global).length;
      this.toggleClass(t.hasMaxTags, (this || _global).value.length >= (this || _global).settings.maxTags), this.toggleClass(t.hasNoTags, !(this || _global).value.length), this.toggleClass(t.empty, !e);
    },

    setOriginalInputValue(t) {
      var e = (this || _global).DOM.originalInput;
      (this || _global).settings.mixMode.integrated || (e.value = t, e.tagifyValue = e.value, this.setPersistedData(t, "value"));
    },

    update(t) {
      var e = this.getInputValue();
      this.setOriginalInputValue(e), this.postUpdate(), (t || {}).withoutChangeEvent || (this || _global).state.blockChangeEvent || this.triggerChangeEvent();
    },

    getInputValue() {
      var t = this.getCleanValue();
      return "mix" == (this || _global).settings.mode ? this.getMixedTagsAsString(t) : t.length ? (this || _global).settings.originalInputValueFormat ? (this || _global).settings.originalInputValueFormat(t) : JSON.stringify(t) : "";
    },

    getCleanValue(t) {
      return a(t || (this || _global).value, (this || _global).dataProps);
    },

    getMixedTagsAsString() {
      var t = "",
          e = this || _global,
          i = (this || _global).settings.mixTagsInterpolator;
      return function s(a) {
        a.childNodes.forEach(a => {
          if (1 == a.nodeType) {
            const o = e.tagData(a);
            if ("BR" == a.tagName && (t += "\r\n"), a.getAttribute("style") || ["B", "I", "U"].includes(a.tagName)) t += a.textContent;else if ("DIV" == a.tagName || "P" == a.tagName) t += "\r\n", s(a);else if (v.call(e, a) && o) {
              if (o.__removed) return;
              t += i[0] + JSON.stringify(n(o, e.dataProps)) + i[1];
            }
          } else t += a.textContent;
        });
      }((this || _global).DOM.input), t;
    }

  }, M.prototype.removeTag = M.prototype.removeTags, M;
});
export default exports;